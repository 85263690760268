body {
  margin: 0;
  font-family: SF UI Text Regular !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
  font-family: "SF UI Text Regular";
  font-style: normal;
  font-weight: normal;
  src: local("SF UI Text Regular"),
    url("/public/SFUIText-Regular.woff") format("woff");
}

@font-face {
  font-family: "SF UI Text Italic";
  font-style: normal;
  font-weight: normal;
  src: local("SF UI Text Italic"),
    url("/public/SFUIText-RegularItalic.woff") format("woff");
}

@font-face {
  font-family: "SF UI Text Light";
  font-style: normal;
  font-weight: normal;
  src: local("SF UI Text Light"),
    url("/public/SFUIText-Light.woff") format("woff");
}

@font-face {
  font-family: "SF UI Text Light Italic";
  font-style: normal;
  font-weight: normal;
  src: local("SF UI Text Light Italic"),
    url("/public/SFUIText-LightItalic.woff") format("woff");
}

@font-face {
  font-family: "SF UI Text Medium";
  font-style: normal;
  font-weight: normal;
  src: local("SF UI Text Medium"),
    url("/public/SFUIText-Medium.woff") format("woff");
}

@font-face {
  font-family: "SF UI Text Medium Italic";
  font-style: normal;
  font-weight: normal;
  src: local("SF UI Text Medium Italic"),
    url("/public/SFUIText-MediumItalic.woff") format("woff");
}

@font-face {
  font-family: "SF UI Text Semibold";
  font-style: normal;
  font-weight: normal;
  src: local("SF UI Text Semibold"),
    url("/public/SFUIText-Semibold.woff") format("woff");
}

@font-face {
  font-family: "SF UI Text Semibold Italic";
  font-style: normal;
  font-weight: normal;
  src: local("SF UI Text Semibold Italic"),
    url("/public/SFUIText-SemiboldItalic.woff") format("woff");
}

@font-face {
  font-family: "SF UI Text Bold";
  font-style: normal;
  font-weight: normal;
  src: local("SF UI Text Bold"),
    url("/public/SFUIText-Bold.woff") format("woff");
}

@font-face {
  font-family: "SF UI Text Bold Italic";
  font-style: normal;
  font-weight: normal;
  src: local("SF UI Text Bold Italic"),
    url("/public/SFUIText-BoldItalic.woff") format("woff");
}

@font-face {
  font-family: "SF UI Text Heavy";
  font-style: normal;
  font-weight: normal;
  src: local("SF UI Text Heavy"),
    url("/public/SFUIText-Heavy.woff") format("woff");
}

@font-face {
  font-family: "SF UI Text Heavy Italic";
  font-style: normal;
  font-weight: normal;
  src: local("SF UI Text Heavy Italic"),
    url("/public/SFUIText-HeavyItalic.woff") format("woff");
}
