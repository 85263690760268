.slick-track {
  display: flex !important;
  align-items: center !important;
}

/* .slick-slider .slick-list,
.slick-slider .slick-track {
  display: flex !important;
  align-items: center !important;
} */
